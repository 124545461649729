@import '../home';
@import './common';

@import '~@mshops-components-library/item/src/styles/desktop';
@import '~@mshops-components-library/common/src/Components/Section/styles/desktop';
@import '~@mshops-components-library/video-and-text/src/styles/general/desktop';
@import '~@mshops-components-library/video-and-text/src/styles/eshops/desktop';
@import '~@mshops-components-library/snapped-collection/src/styles/recommendations/desktop';
@import '~@mshops-components-library/categories-gallery/src/styles/base-seller/desktop';
@import '~@mshops-components-library/categories-gallery/src/CategoryLabelImage/styles/base-seller/desktop';

@import 'eshops-components-library/src/components/ProfileHeader/lib/desktop';
@import 'eshops-components-library/src/components/EmptyState/lib/desktop';
@import '@mshops-components-library/eshop-item/styles/desktop';
@import '@mshops-components-library/coupon-carousel/src/styles/index';
@import '@mshops-components-library/coupon-carousel/src/styles/desktop';

@import '../../../../components/payment-data/desktop';
@import '../../../../components/badge-carousel/styles/desktop';
@import '../../../../components/site-shopping-info/desktop';
@import '../../../../components/message/styles/desktop';
@import '~@mshops-web-components/newsletter-modal/styles/default/desktop';
@import '../../../../appearance/buyerInfo/styles/desktop';

// Video player
@import '../../../../components/VideoPlayer/styles/desktop';

//Report Page
@import '../../../../components/report-page/styles/desktop';

@import '../../../../components/recommendation-container/styles/desktop';

// Contact Page
@import '../../../../components/contact-form/styles/desktop';
@import '../../../../components/contact-info/styles/desktop';

// Cancel Buy Landing
@import '../../../../appearance/cancelBuyInfo/styles/desktop';

// Bank Conditions Landing
@import '../../../../appearance/bankConditionsInfo/styles/desktop';

// LGPD Landing
@import '../../../../appearance/dataPrivacyInfo/styles/desktop';

// Legal Notices Landing
@import '../../../../components/LegalNoticesInfo/styles/desktop';

// Adhesion contracts Landing
@import '../../../../appearance/adhesionContractInfo/styles/desktop';

@import '~@mshops-components-library/item/src/styles/simple/desktop';

@import '@mshops-components-library/recommendations-carousel/styles/desktop';

// Relevant Item Container
@import '@mshops-components-library/relevant-item-container/styles/desktop';

.home {
  .row {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .hub_carousel {
    max-width: rem(1250px);

    .carousel-container {
      max-width: 95%;
    }

    .arrow-disabled {
      cursor: default;
    }
  }
}

.mshops-recommendations-wrapper .section-eshop__title--layout {
  padding: 0 rem(8px);

  .section-header__title--base {
    width: auto;
  }
}

.recommendations.ui-ms-section-eshops {
  .row.container {
    margin-top: rem(42px);
    padding-top: 0;
  }
}

.andes-carousel-snapped__wrapper {
  height: rem(400px);
}

.home--seller .andes-carousel-snapped--scroll-hidden {
  margin: 0;
  padding: 0;
}

.ui-recos-carousel-wrapper {
  .ui-recos-carousel-wrapper__title {
    padding: 0 rem($andes-spacing-8);
  }

  .ui-recos-carousel-wrapper__header {
    margin-bottom: rem($andes-spacing-24);
  }
}

section {
  > .container {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.ui-ms-polycard-carousel,
.ui-ms-products-gallery__polycard {
  .ui-ms-products-gallery__container li:not(.andes-card.poly-card li) {
    display: flex;
    width: 20%;
  }
}

div.mshops-recommendations-wrapper.ui-ms-polycard-carousel--offers {
  background-color: $andes-white;
  margin-top: 0px !important;
  padding-top: rem($andes-spacing-40);
}

.ui-ms-polycard-container {
  .poly-card--list .poly-component__title, .poly-card--list-card .poly-component__title {
    color: $andes-gray-900;
  }

  .poly-component__action-links .poly-action-links__action--button .andes-button {
      font-size: rem($font-size-14);
      height: rem(32px);
      width: rem(263px);
  }

  .poly-column__title:first-child {
    color: $andes-gray-900;
  }

  .poly-card--list .poly-card__portada, .poly-card--list-card .poly-card__portada, .poly-column__portada--list, .poly-column__portada--list-card {
    height: rem(196px);
    width: rem(196px);
  }

  .poly-card__portada {
    border-bottom-left-radius: rem($border-radius-6);
    border-bottom-right-radius: rem($border-radius-6);
    min-width: rem(196px);
    min-height: rem(196px);
  }

  .poly-content {
    gap: rem(200px);

    .poly-content__column {
      max-width: rem(290px);
    }
  }
}

.ui-relevant-item-container--editable {
  .ui-ms-polycard-container .ui-relevant-item__default-image .poly-card--list .poly-card__portada, .ui-ms-polycard-container .poly-card--list-card .poly-card__portada, .ui-ms-polycard-container .poly-column__portada--list, .ui-ms-polycard-container .poly-column__portada--list-card  {
    background-image: url('https://http2.mlstatic.com/storage/mshops-appearance-api/resources/default/default-shoes.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: rem(160px);
  };
  
  .poly-component__action-links .poly-action-links__action--button .andes-button {
    background-color: $andes-gray-100;
    color: $andes-gray-900-solid;
    width: rem(296px);
  }

  .poly-card__content {
    justify-content: center;
  }

  .poly-card--list, .poly-card--list-card {
    padding: rem($andes-spacing-32 $andes-spacing-64 $andes-spacing-32 $andes-spacing-32);
  }

  .poly-content {
    justify-content: space-between;
  }
}

.ui-relevant-item-container {
  margin-top: rem($andes-spacing-24);
  padding: 0 rem($andes-spacing-12);
}

.section-category-item--hover .section-category-item__header {
  opacity: 1;
}
