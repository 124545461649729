.ui-ms-report-page-wrapper {
  align-items: center;
  border-top: rem(1px) solid $andes-gray-100;
  display: flex;
  gap: rem($andes-spacing-16);
  height: rem(18px);
  justify-content: end;
  padding-bottom: rem($andes-spacing-40);
  padding-top: rem($andes-spacing-24);

  .ui-ms-report-page-wrapper__link {
    color: $andes-blue-500;
    cursor: pointer;
    font-size: rem($font-size-14);
    font-weight: $font-weight-regular;
  }

  .ui-ms-report-page-wrapper__link--share {
    align-items: center;
    border-right: 1px solid $andes-gray-100-solid;
    display: flex;
    gap: ($andes-spacing-8);
    padding-right: $andes-spacing-16;
  }
}
