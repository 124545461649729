@import '~@polycard/phrase/build';
@import '~@polycard/card/build';

$width-desktop-eshops: 1180px;

.home {
  padding-top: 0;
  padding-bottom: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.ui-ms-profile__header {
  @include z-index(eshop-header);

  margin-top: 0;
  position: sticky;
  top: 0;
}

.empty-state {
  gap: rem($andes-spacing-12);
  margin: 0 auto;
  margin-top: rem(100px);
}

.slider {
  margin-bottom: rem($andes-spacing-24);
}

.ui-ms-profile--with-adult-modal ~ .component-wrapper {
  display: none;
}

.ui-recos-carousel-wrapper__text {
  user-select: none;
}

.ui-ms-polycard-carousel,
.ui-ms-products-gallery__polycard {
  .poly-card--grid-card .poly-card__content:not(:last-child) { 
    padding: var(--poly-padding-content);
  }

  .grid-container.ui-ms-grid-container__eshops.ui-ms-grid-container__eshops--seller {
    display: flex;
    justify-content: start;
  }

  .poly-card--grid-card {
    margin-bottom: rem(1px);
  }
}

.ui-ms-polycard-carousel {
  .andes-carousel-snapped__wrapper {
    height: auto;
  }

  .andes-carousel-snapped__slide {
    height: auto;
  }
}
