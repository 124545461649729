.eshop-home-nav-tabs {
  background-color: $andes-white;
  border-top: rem(1px) solid $home-gray-100;
  box-shadow: 0 rem($andes-spacing-8) rem($andes-spacing-16) 0 $home-gray-100;

  .andes-tab:hover:not(.andes-tab--disabled) {
    color: $andes-black;
  }

  .andes-tab:hover:not(.andes-tab--disabled).andes-tab--selected {
    color: $blue-100;
  }

  .andes-tab:focus-visible {
    box-shadow: none;
  }

  .andes-tab:hover:not(.andes-tab--disabled):not(.andes-tab--stretched) .andes-tab__link:after {
    background-color: $blue-100 !important;
  }

  .andes-tab:active:not(.andes-tab--disabled), .andes-tab.andes-tab--selected:active:not(.andes-tab--disabled) {
    background-color: transparent;
  }
}

.eshops-menu-tabs--ios {
  @include z-index(header);
  position: fixed;
  top: rem(64px);
}