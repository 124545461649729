.video-text--video-variant1 {
  .video-and-text.video-and-text__container {
    display: flex;
  }

  .video-and-text__container .video-and-text__container-description {
    display: inline-grid;
  }
}

.video-text--video-variant1,
.video-text--video-variant3 {
  .video-and-text__youtube-player,
  .video-and-text__image-player {
    height: rem(468px);
    max-width: rem(832px);
    width: rem(832px);
  }
}

.video-and-text__image-player-cover {
  object-fit: cover;
}

.video-text--video-variant2 {
  .video-and-text.video-and-text__container {
    aspect-ratio: 16/9;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: rem(468px);
    justify-content: center;
    object-fit: contain;
    overflow: hidden;
  }

  .video-and-text__col.video-and-text__container-video {
    grid-column: 1/2;
    grid-row: 1/2;
    margin: auto;
    width: 100%;
  }

  .video-and-text__youtube-player,
  .video-and-text__image-player {
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  .video-and-text__image--center svg {
    z-index: 2;
  }
}

.video-text--video-variant3 {
  .video-and-text.video-and-text__container {
    aspect-ratio: 16/9;
    display: inline-block;
    object-fit: contain;
  }

  .video-and-text__col.video-and-text__container-video {
    margin: auto;
    width: inherit;
  }
}

.video-and-text__image--center {
  aspect-ratio: 16/9;
}

.video-and-text .video-and-text__youtube-player-variant2,
.video-and-text .video-and-text__image-player-variant3 {
  height: auto;
  object-fit: cover;
  width: 100%;
}

.video-text--video-variant1 .video-and-text__image--center svg {
  left: 50%;
}

.video-and-text {
  display: inline-flex;
  width: 100%;

  .video-and-text__container .video-and-text__container-title {
    font-weight: $font-weight-semibold;
    margin-bottom: rem($andes-spacing-20);
  }

  .video-and-text__container .video-and-text__container-description p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .video-and-text__container-text {
    margin: auto;
    order: 2;
  }

  .video-and-text__container-text-variant2 {
    align-self: flex-end;
    color: $andes-white;
    grid-column: 1/2;
    grid-row: 1/3;
    z-index: 1;
  }

  .video-and-text__youtube-player-variant3 {
    width: 100%;
  }

  .video-and-text__container-text-variant3 {
    order: 2;
  }

  .video-and-text__container-text-remove-text {
    display: none;
  }

  .video-and-text__contaniner--video {
    display: flex;
    order: 1;
    text-align: right;
  }
}
