.video-and-text__image--center {
  position: relative;

  .react-player__play-icon {
    display: none;
  }
  
  .react-player__shadow {
    display: none !important;
  }
}

.ui-ms-overlay-static,
.ui-ms-overlay-video-image {
  background-size: cover;
  background-position: center;
  bottom: rem(50px);
  display: none;
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
}

.ui-ms-overlay-static {
  display: block;
}

.ui-ms-background-without-image {
  background-color: black;
}

.ui-ms-overlay-video-image--show .ui-ms-overlay-video-image {
  display: block;
  bottom: 0px;
}

.video-and-text .video-and-text__youtube-player-variant2,
.video-and-text .video-and-text__image-player-variant3 {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.ui-ms-overlay-video-image.ui-ms-overlay-video-image--initial {
  position: relative;
}

.mshops-web-components-clickable.video-and-text__image-player-variant3.ui-ms-background-transparent,
.mshops-web-components-clickable.video-and-text__image-player.ui-ms-background-transparent {
  background-color: transparent;
  border: none;
  display: block;
  height: 100% !important;
}