@import 'core';
@import 'player';

/* General styles */
.video-and-text {
  .video-and-text__container .video-and-text__container-title {
    color: $andes-gray-900;
    font-size: rem($font-size-24);
    line-height: $line-height-s;
    margin: rem(0 0 $andes-spacing-16 0);
  }

  .video-and-text__container-description {
    color: $andes-gray-550;
    font-size: rem($font-size-16);
    line-height: $line-height-s;
  }
}

.video-text--video-variant1 .video-and-text__image--center,
.video-text--video-variant2 .video-and-text__image--center,
.video-text--video-variant3 .video-and-text__image--center {
  svg {
    height: rem(48px);
    width: rem(48px);
  }
}

.video-text--video-variant2 .video-and-text.video-and-text__container {
  background: none !important;
}

.video-text--video-variant1,
.video-text--video-variant2,
.video-text--video-variant3 {
  > .container {
    display: flex;
    justify-content: center;
    max-width: rem($width-desktop-eshops);
    padding: rem($andes-spacing-40 0);
    width: rem($width-desktop-eshops);
  }

  .video-and-text__youtube-player,
  .video-and-text__image-player {
    height: rem(360px);
    max-width: rem(640px);
    width: rem(640px);

    iframe {
      height: rem(360px);
      max-width: rem(640px);
      width: rem(640px);
    }
  }
}
/* End General styles */

/* Variant 1 */
.video-text--video-variant1 {
  .video-and-text__container-text {
    padding-left: rem($andes-spacing-40);
  }

  .video-and-text__container-text {
    margin: auto 0;
    order: 2;
  }

  .video-and-text__youtube-player,
  .video-and-text__image-player {
    height: rem(360px) !important;
    width: rem(640px) !important;
  }
}
/* End Variant 1 */

/* Variant 2 */
.video-text--video-variant2 {
  height: auto;
  margin: rem($andes-spacing-32) 0;
  width: 100%;

  > .container {
    padding: 0;
    background-color: $andes-black;
  }

  .video-and-text__container-text-variant2 {
    padding: rem(0 0 $andes-spacing-40 $andes-spacing-40);
  }

  .video-and-text__container .video-and-text__container-title,
  .video-and-text__container-description {
    color: $andes-white;
  }

  .video-and-text__youtube-player,
  .video-and-text__image-player,
  .video-and-text {
    height: rem(468px) !important;
    max-width: rem(832px);
    width: rem(832px);

    iframe {
      height: rem(468px);
      max-width: rem(832px);
      width: rem(832px);
    }
  }

  .ui-ms-overlay-video-image img {
    height: rem(468px);
    width: rem(832px);
  }
}
/* End Variant 2 */

/* Variant 3 */
.video-text--video-variant3 {
  > .container {
    width: 100%;
  }

  .video-and-text__container-text-variant3 {
    margin: rem(0 0 $andes-spacing-16 0);
  }

  .video-and-text.video-and-text__container {
    aspect-ratio: auto !important;
  }

  .video-and-text__col.video-and-text__container-video {
    background-color: $andes-black;
    display: flex;
    height: rem(360px);
    justify-content: center;
  }

  .video-and-text__youtube-player-variant1,
  .video-and-text__image--center,
  .video-and-text__image-player-variant3,
  .video-and-text__youtube-player-variant3 {
    aspect-ratio: 16 / 9 !important;
    height: rem(360px) !important;
    width: rem(640px) !important;

    iframe {
      height: rem(360px);
      width: rem(640px);
    }
  }

  .ui-ms-overlay-video-image img {
    height: rem(360px);
    width: rem(640px);
  }
}