.ui-recos-carousel-wrapper {
  .ui-recommendations-title {
    display: none;
  }

  .ui-recos-carousel-wrapper__text svg {
    display: inline-block;
    margin-left: rem($andes-spacing-4);
    vertical-align: rem(-2px);

    path {
      fill: $andes-gray-550;
    }
  }
}
